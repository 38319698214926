import { lazyRetry } from "lazyRetry";

// import * as Permissions from "permission.js";
import * as License from "license.js";

// @material-ui/icons
import TrackChanges from "@material-ui/icons/TrackChanges";
import Payment from "@material-ui/icons/Payment";
import EuroSymbol from "@material-ui/icons/EuroSymbol";

const Tracker = lazyRetry(() => import("views/Tracker/Tracker.js"));
const Cashier = lazyRetry(() => import("views/Cashier/Cashier.js"));
const FinishSale = lazyRetry(() => import("views/FinishSale/FinishSale.js"));
// const CustomCompanyPage = lazyRetry(() => import("views/CustomCompanyPage/CustomCompanyPage.js"));

var dashRoutes = [
  {
    sortFirst: 3,
    path: "/tracker",
    name: "TRACKER",
    icon: TrackChanges,
    component: Tracker,
    license: [License.ICETRUCK, License.ICESHOP],
    layout: "/admin"
  },
  {
    sortFirst: 2,
    path: "/cashier",
    name: "CASHIER",
    icon: Payment,
    component: Cashier,
    license: License.ICECASH,
    isIcecreamVan: true,
    layout: "/admin"
  },
  {
    sortFirst: 1,
    path: "/finishsale",
    name: "FINISH_SALE",
    icon: EuroSymbol,
    component: FinishSale,
    license: License.ICECASH,
    isIcecreamVan: true,
    hidden: true,
    layout: "/admin"
  },
  // {
  //   path: "/customcompanypage",
  //   name: "CUSTOM_COMPANY_PAGE",
  //   icon: History,
  //   component: CustomCompanyPage,
  //   hidden: false,
  //   layout: "/admin"
  // },
];
export default dashRoutes;
