import * as actionType from './I18nActionType';

const initialState = {
    locale: "en",
}

const I18nReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_LOCALE:
      return {...state, locale: action.payload};
    
    default:
      return state
  }
}

export default I18nReducer;