import * as actionType from './LayersActionType';

const initialState = {
    showZone: true,
    showLine: true,
    showCalls: true,
    showReservations: true,
    showPois: true,
    showWebshopOrders: true,
    trackedObject: null,
    trackedLocation: null,
    defaultMusic: null,
}

const LayersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_SHOW_ZONE:
      return {...state, showZone: action.payload};
    case actionType.SET_SHOW_LINE:
      return {...state, showLine: action.payload};
    case actionType.SET_SHOW_CALLS:
      return {...state, showCalls: action.payload};
    case actionType.SET_SHOW_RESERVATIONS:
      return {...state, showReservations: action.payload};
    case actionType.SET_SHOW_POIS:
      return {...state, showPois: action.payload};
    case actionType.SET_SHOW_WEBSHOP_ORDERS:
      return {...state, showWebshopOrders: action.payload};
    case actionType.SET_TRACKED_OBJECT:
      return {...state, trackedObject: action.payload};
    case actionType.CLEAR_TRACKED_OBJECT:
      return {...state, trackedObject: ((action.payload.clearIfType == null || action.payload.clearIfType == (state.trackedObject?.type ?? null)) && (action.payload.clearIfId == null || action.payload.clearIfId == (state.trackedObject?.id ?? null))) ? null : state.trackedObject};
    case actionType.SET_TRACKED_LOCATION:
      return {...state, trackedLocation: action.payload};
    case actionType.SET_DEFAULT_MUSIC:
      return {...state, defaultMusic: action.payload};
    default:
      return state
  }
}

export default LayersReducer;