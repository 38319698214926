import * as actionType from './AuthActionType';

const initialState = {
    authTablet: null,
    token: null,

    currentShift: null,
    currentOrNextIcecreamVanSchedule: null,
    currentOrNextDeliveryVanSchedule: null,
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_AUTH_TABLET:
      return {...state, authTablet: action.payload};

    case actionType.SET_TOKEN:
      return {...state, token: action.payload};

    case actionType.CLEAR_AUTH:
      return {...state, token: null, authTablet: null};

    case actionType.SET_CURRENT_SHIFT:
      return {...state, currentShift: action.payload};

    case actionType.CLEAR_CURRENT_SHIFT:
      return {...state, currentShift: null};

    case actionType.SET_CURRENT_OR_NEXT_ICECREAM_VAN_SCHEDULE:
      return {...state, currentOrNextIcecreamVanSchedule: action.payload};

    case actionType.CLEAR_CURRENT_OR_NEXT_ICECREAM_VAN_SCHEDULE:
      return {...state, currentOrNextIcecreamVanSchedule: null};

    case actionType.SET_CURRENT_OR_NEXT_DELIVERY_VAN_SCHEDULE:
      return {...state, currentOrNextDeliveryVanSchedule: action.payload};

    case actionType.CLEAR_CURRENT_OR_NEXT_DELIVERY_VAN_SCHEDULE:
      return {...state, currentOrNextDeliveryVanSchedule: null};
    
    default:
      return state
  }
}

export default authReducer;