import * as actionType from './DailyStorageActionType';

const initialState = {
  hiddenIcecreamVanCalls: [],
  hiddenIcecreamVanReservations: [],
  hiddenWebshopOrders: [],
  lineDatapoints: [],
}

const I18nReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.CLEAR_DAILY_STORAGE:
      return {...state, hiddenIcecreamVanCalls: [], hiddenIcecreamVanReservations: [], hiddenWebshopOrders: [], lineDatapoints: []};
    case actionType.HIDE_ICECREAM_VAN_CALL:
      return {...state, hiddenIcecreamVanCalls: [...state.hiddenIcecreamVanCalls, action.payload]};
    case actionType.HIDE_ICECREAM_VAN_RESERVATION:
      return {...state, hiddenIcecreamVanReservations: state.hiddenIcecreamVanReservations ? [...state.hiddenIcecreamVanReservations, action.payload] : [action.payload]};
    case actionType.HIDE_WEBSHOP_ORDER:
      return {...state, hiddenWebshopOrders: state.hiddenWebshopOrders ? [...state.hiddenWebshopOrders, action.payload] : [action.payload]};
    case actionType.ADD_LINE_DATAPOINT:
      return {...state, lineDatapoints: [...state.lineDatapoints, action.payload]};
    default:
      return state
  }
}

export default I18nReducer;