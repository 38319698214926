// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React from 'react'
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom'
import {store} from "index";
import { setauthTablet, clearAuth } from "Redux/AuthActions";
import API from 'api';

class PrivateRoute extends React.Component {
  constructor(props) {
    super(props);

    this.handleLoad = this.handleLoad.bind(this);
  }

  componentDidMount() {
    window.addEventListener("load", this.handleLoad);
  }

  handleLoad() {
    API.get("tablet/me").then(res => {
      const data = res.data.data;
      store.dispatch(setauthTablet(data));
    }).catch(function (error) {
      console.log(error);
      if (!error.response) {
        //The error is caused by no internet
        //Just keep the last known authState
      } else {
        store.dispatch(clearAuth());
      }
    }).finally(function () {
      // always executed
    });
  }

  render() {
    const { component: Component, authTablet, ...rest } = this.props;

    // Add your own authentication on the below line.
    const isLoggedIn = !(!this.props.authTablet);

    return (
      <Route
        {...rest}
        render={props =>
          isLoggedIn ? (
            <Component {...props} authTablet={authTablet} />
          ) : (
            <Redirect push to={{ pathname: '/auth/login-page', state: { from: props.location } }} />
          )
        }
      />
    )
  }
}

function mapStateToProps(state) {
  return {
      authTablet: state.auth.authTablet || null,
  }
}

export default connect(mapStateToProps)(PrivateRoute);