import * as actionType from './CashActionType';

export function setMenus(menus){
    return {
        type: actionType.SET_MENUS,
        payload: menus,
    }
};

export function setMenu(menu){
    return {
        type: actionType.SET_MENU,
        payload: menu,
    }
};

export function setIcecreamVanReservation(icecreamVanReservation){
    return {
        type: actionType.SET_ICECREAM_VAN_RESERVATION,
        payload: icecreamVanReservation,
    }
};

export function setSale(sale){
    return {
        type: actionType.SET_SALE,
        payload: sale,
    }
};

export function setSaleId(saleNonceId, saleId){
    return {
        type: actionType.SET_SALE_ID,
        payload: {saleNonceId: saleNonceId, saleId: saleId},
    }
};

export function setSaleInvoiceFields(saleNonceId, needsInvoice = false, allowAutoInvoice = true, invoiceDiscount = 0, invoiceName = null, invoiceEnterpriseNr = null, invoiceReference = null, invoiceReferencePending = false, invoiceEmail = null, invoiceStreet = null, invoiceNr = null, invoiceBus = null, invoiceZipcode = null, invoiceCity = null, invoiceCountry = null){
    return {
        type: actionType.SET_SALE_INVOICE_FIELDS,
        payload: {
            saleNonceId: saleNonceId,
            needs_invoice: needsInvoice,
            allow_auto_invoice: allowAutoInvoice,
            invoice_discount: invoiceDiscount,
            invoice_name: invoiceName,
            invoice_enterprise_nr: invoiceEnterpriseNr,
            invoice_reference: invoiceReference,
            invoice_reference_pending: invoiceReferencePending,
            invoice_email: invoiceEmail,
            invoice_street: invoiceStreet,
            invoice_nr: invoiceNr,
            invoice_bus: invoiceBus,
            invoice_zipcode: invoiceZipcode,
            invoice_city: invoiceCity,
            invoice_country: invoiceCountry
        },
    }
};

export function setSaleStopFields(saleNonceId, datetime, latitude, longitude, icecreamVanReservationId, needsInvoice = false, allowAutoInvoice = true, invoiceDiscount = 0, invoiceName = null, invoiceEnterpriseNr = null, invoiceReference = null, invoiceReferencePending = false, invoiceEmail = null, invoiceStreet = null, invoiceNr = null, invoiceBus = null, invoiceZipcode = null, invoiceCity = null, invoiceCountry = null){
    return {
        type: actionType.SET_SALE_STOP_FIELDS,
        payload: {
            saleNonceId: saleNonceId,
            datetime: datetime,
            latitude: latitude,
            longitude: longitude,
            icecreamVanReservationId: icecreamVanReservationId,
            needs_invoice: needsInvoice,
            allow_auto_invoice: allowAutoInvoice,
            invoice_discount: invoiceDiscount,
            invoice_name: invoiceName,
            invoice_enterprise_nr: invoiceEnterpriseNr,
            invoice_reference: invoiceReference,
            invoice_reference_pending: invoiceReferencePending,
            invoice_email: invoiceEmail,
            invoice_street: invoiceStreet,
            invoice_nr: invoiceNr,
            invoice_bus: invoiceBus,
            invoice_zipcode: invoiceZipcode,
            invoice_city: invoiceCity,
            invoice_country: invoiceCountry
        },
    }
};

export function setSaleStopFieldsSent(saleNonceId, sent){
    return {
        type: actionType.SET_STOP_FIELDS_SENT,
        payload: {saleNonceId: saleNonceId, sent: sent},
    }
};

export function addSaleOrder(saleOrder){
    return {
        type: actionType.ADD_SALE_ORDER,
        payload: saleOrder,
    }
};

export function setSaleOrderId(saleOrderNonceId, saleOrderId){
    return {
        type: actionType.SET_SALE_ORDER_ID,
        payload: {saleOrderNonceId: saleOrderNonceId, saleOrderId: saleOrderId},
    }
};

export function removeSaleOrder(saleOrderNonceId){
    return {
        type: actionType.REMOVE_SALE_ORDER,
        payload: saleOrderNonceId,
    }
};

export function permanentlyRemoveSaleOrder(saleOrderId){
    return {
        type: actionType.PERMANENTLY_REMOVE_SALE_ORDER,
        payload: saleOrderId,
    }
};

export function addPayment(payment){
    return {
        type: actionType.ADD_PAYMENT,
        payload: payment,
    }
};

export function setPaymentId(paymentNonceId, paymentId){
    return {
        type: actionType.SET_PAYMENT_ID,
        payload: {paymentNonceId: paymentNonceId, paymentId: paymentId},
    }
};

export function setPaymentStatus(paymentId, paymentStatus){
    return {
        type: actionType.SET_PAYMENT_STATUS,
        payload: {paymentId: paymentId, paymentStatus: paymentStatus},
    }
};

export function moveSaleToSpool(){
    return {
        type: actionType.MOVE_SALE_TO_SPOOL
    }
};

export function permanentlyRemoveSaleFromSpool(saleId){
    return {
        type: actionType.PERMANENTLY_REMOVE_SALE_FROM_SPOOL,
        payload: saleId,
    }
};

export function incrementSaleCounter(){
    return {
        type: actionType.INCREMENT_SALE_COUNTER,
    }
};

export function incrementSaleOrderCounter(){
    return {
        type: actionType.INCREMENT_SALE_ORDER_COUNTER,
    }
};

export function incrementPaymentCounter(){
    return {
        type: actionType.INCREMENT_PAYMENT_COUNTER,
    }
};

export function setLatestSaleAt(datetime){
    return {
        type: actionType.SET_LATEST_SALE_AT,
        payload: datetime,
    }
};