export const SET_MENUS = 'SET_MENUS';
export const SET_MENU = 'SET_MENU';
export const SET_ICECREAM_VAN_RESERVATION = 'SET_ICECREAM_VAN_RESERVATION';
export const SET_SALE = 'SET_SALE';
export const SET_SALE_ID = 'SET_SALE_ID';
export const SET_SALE_INVOICE_FIELDS = 'SET_SALE_INVOICE_FIELDS';
export const SET_SALE_STOP_FIELDS = 'SET_SALE_STOP_FIELDS';
export const SET_STOP_FIELDS_SENT = 'SET_STOP_FIELDS_SENT';
export const ADD_SALE_ORDER = 'ADD_SALE_ORDER';
export const SET_SALE_ORDER_ID = 'SET_SALE_ORDER_ID';
export const REMOVE_SALE_ORDER = 'REMOVE_SALE_ORDER';
export const PERMANENTLY_REMOVE_SALE_ORDER = 'PERMANENTLY_REMOVE_SALE_ORDER';
export const ADD_PAYMENT = 'ADD_PAYMENT';
export const SET_PAYMENT_ID = 'SET_PAYMENT_ID';
export const SET_PAYMENT_STATUS = 'SET_PAYMENT_STATUS';
export const MOVE_SALE_TO_SPOOL = 'MOVE_SALE_TO_SPOOL';
export const PERMANENTLY_REMOVE_SALE_FROM_SPOOL = 'PERMANENTLY_REMOVE_SALE_FROM_SPOOL';
export const INCREMENT_SALE_COUNTER = 'INCREMENT_SALE_COUNTER';
export const INCREMENT_SALE_ORDER_COUNTER = 'INCREMENT_SALE_ORDER_COUNTER';
export const INCREMENT_PAYMENT_COUNTER = 'INCREMENT_PAYMENT_COUNTER';
export const SET_LATEST_SALE_AT = 'SET_LATEST_SALE_AT';