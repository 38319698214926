import * as actionType from './AuthActionType';

export function setauthTablet(authTablet){
    return {
        type: actionType.SET_AUTH_TABLET,
        payload: authTablet,
    }
};

export function setToken(token){
    return {
        type: actionType.SET_TOKEN,
        payload: token,
    }
};

export function clearAuth(){
    return {
        type: actionType.CLEAR_AUTH,
        payload: null,
    }
};

export function setCurrentShift(currentShift){
    return {
        type: actionType.SET_CURRENT_SHIFT,
        payload: currentShift,
    }
};

export function clearCurrentShift(){
    return {
        type: actionType.CLEAR_CURRENT_SHIFT,
    }
};

export function setCurrentOrNextIcecreamVanSchedule(currentOrNextIcecreamVanSchedule){
    return {
        type: actionType.SET_CURRENT_OR_NEXT_ICECREAM_VAN_SCHEDULE,
        payload: currentOrNextIcecreamVanSchedule,
    }
};

export function clearCurrentOrNextIcecreamVanSchedule(){
    return {
        type: actionType.CLEAR_CURRENT_OR_NEXT_ICECREAM_VAN_SCHEDULE,
    }
};

export function setCurrentOrNextDeliveryVanSchedule(currentOrNextDeliveryVanSchedule){
    return {
        type: actionType.SET_CURRENT_OR_NEXT_DELIVERY_VAN_SCHEDULE,
        payload: currentOrNextDeliveryVanSchedule,
    }
};

export function clearCurrentOrNextDeliveryVanSchedule(){
    return {
        type: actionType.CLEAR_CURRENT_OR_NEXT_DELIVERY_VAN_SCHEDULE,
    }
};