// @material-ui/core components
import { withStyles } from '@material-ui/core/styles';
import API from 'api';
import { store } from "index";
import React from "react";
import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { permanentlyRemoveSaleFromSpool, permanentlyRemoveSaleOrder, setPaymentId, setSaleId, setSaleOrderId, setSaleStopFieldsSent } from "Redux/CashActions";

class SaleSpooler extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    }
    
    this.spoolSales = this.spoolSales.bind(this);
    this.handleSale = this.handleSale.bind(this);
  }

  componentDidMount() {
    this.spoolSalesInterval = setInterval(this.spoolSales, 10000);
  }

  componentWillUnmount() {
    if (this.spoolSalesInterval) clearInterval(this.spoolSalesInterval);
  }

  spoolSales() {
    const that = this;

    if (that.props.sale) {
      this.handleSale(that.props.sale, false);
    }

    that.props.spooled_sales.forEach(sale => this.handleSale(sale, true));
  }

  handleSale(sale, isSpooled) {
    if (sale === null) {
      store.dispatch(permanentlyRemoveSaleFromSpool(null));
      return;
    }
    if (!sale.id) {
      const formdata = {
        nonce_id: sale.nonce_id,
        sale_counter: sale.sale_counter,
        shift_id: sale.shift_id,
        icecream_van_reservation_id: sale.icecream_van_reservation_id,
        datetime_start: sale.datetime_start,
        latitude_start: sale.latitude_start,
        longitude_start: sale.longitude_start,
      };

      API.post("sale", formdata).then(res => {
          const data = res.data.data;

          store.dispatch(setSaleId(sale.nonce_id, data.id));
      }).catch(function (error) {
          console.log(error);

          if (error.response.status === 409) {
            //nonce_id already known
            const data = error.response.data.data;

            store.dispatch(setSaleId(sale.nonce_id, data.id));
          }
      }).finally(function () {
      // always executed
      });
    }

    if (sale.id) {
      if (sale.datetime_stop && !sale.stopFieldSent) {
        const formdata = {
          datetime_stop: sale.datetime_stop,
          latitude_stop: sale.latitude_stop,
          longitude_stop: sale.longitude_stop,
          icecream_van_reservation_id: sale.icecream_van_reservation_id,
          needs_invoice: sale.needs_invoice,
          allow_auto_invoice: sale.allow_auto_invoice,
          invoice_discount: sale.invoice_discount,
          invoice_name: sale.invoice_name,
          invoice_enterprise_nr: sale.invoice_enterprise_nr,
          invoice_reference: sale.invoice_reference,
          invoice_reference_pending: sale.invoice_reference_pending,
          invoice_email: sale.invoice_email,
          invoice_street: sale.invoice_street,
          invoice_nr: sale.invoice_nr,
          invoice_bus: sale.invoice_bus,
          invoice_zipcode: sale.invoice_zipcode,
          invoice_city: sale.invoice_city,
          invoice_country: sale.invoice_country
        };
  
        API.patch("sale/"+sale.id, formdata).then(res => {
            // const data = res.data.data;
  
            store.dispatch(setSaleStopFieldsSent(sale.nonce_id, true));
        }).catch(function (error) {
            console.log(error);
        }).finally(function () {
        // always executed
        });
      }

      sale.payments.forEach(payment => {
        if (!payment.id) {
          const paymentData = {
            nonce_id: payment.nonce_id,
            payment_counter: payment.payment_counter,
            paymentable_id: sale.id,
            paymentable_type: "App\\Sale",
            type: payment.type,
            amount: payment.amount,
            status: payment.status,
            datetime: payment.datetime,
          };
    
          API.post("payment", paymentData).then(res => {
              const data = res.data.data;
    
              store.dispatch(setPaymentId(payment.nonce_id, data.id));
          }).catch(function (error) {
              console.log(error);

              if (error.response.status === 409) {
                //nonce_id already known
                const data = error.response.data.data;
    
                store.dispatch(setPaymentId(payment.nonce_id, data.id));
              }
          }).finally(function () {
          // always executed
          });
        }
      });

      sale.sale_orders.forEach(saleOrder => {
        if (saleOrder.id && saleOrder.deleted) {
          API.delete("saleorder/"+saleOrder.id).then(res => {  
            store.dispatch(permanentlyRemoveSaleOrder(saleOrder.id));
          }).catch(function (error) {
              console.log(error);

              if (error.response.status === 404) {
                //saleorder already deleted
                // const data = error.response.data.data;
    
                store.dispatch(permanentlyRemoveSaleOrder(saleOrder.id));
              }
          }).finally(function () {
          // always executed
          });
        }

        if (!saleOrder.id && (!saleOrder.is_discount || saleOrder.deleted)) {
          const saleOrderData = {
            nonce_id: saleOrder.nonce_id,
            sale_order_counter: saleOrder.sale_order_counter,
            sale_id: sale.id,
            menu_item_id: saleOrder.menu_item_id,
            datetime: saleOrder.datetime,
            name: saleOrder.name,
            price_vati: saleOrder.is_discount ? 0 : saleOrder.price_vati,
            vat_rate: saleOrder.vat_rate,
          };
    
          API.post("saleorder", saleOrderData).then(res => {
              const data = res.data.data;
    
              store.dispatch(setSaleOrderId(saleOrder.nonce_id, data.id));
          }).catch(function (error) {
              console.log(error);

              if (error.response.status === 409) {
                //nonce_id already known
                const data = error.response.data.data;
    
                store.dispatch(setSaleOrderId(saleOrder.nonce_id, data.id));
              }
          }).finally(function () {
          // always executed
          });
        }
      });
    }

    if (sale.id && isSpooled) {
      const canBeDeleted = ( 
        ( sale.stopFieldSent && (sale.payments.filter(payment => payment.id == null).length == 0) && (sale.sale_orders.filter(saleOrder => (!saleOrder.is_discount && (saleOrder.id == null || saleOrder.deleted))).length == 0) )
        || ( !sale.datetime_stop ) //Sale was never properly stopped, a bug that shouldn't occur anymore
      );

      if (canBeDeleted) {
        store.dispatch(permanentlyRemoveSaleFromSpool(sale.id));
      }
    }
  }

  render() {
    return (
      <>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    sale: state.cash.sale,
    spooled_sales: state.cash.spooled_sales,
  }
}

export default injectIntl(withStyles((theme) => ({}), {withTheme: true})(connect(mapStateToProps)(SaleSpooler)));