import React, {Component} from "react"
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from "react-intl";
import { Redirect } from "react-router-dom";
import {store} from "index";
import { setToken, setauthTablet } from "Redux/AuthActions";
import API from 'api';
import queryString from 'query-string';

// @material-ui/core components
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CircularIndeterminate from 'components/CircularIndeterminate/CircularIndeterminate.js';

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

class LoginPage extends Component {
  constructor(props) {
    super(props);

    let params = queryString.parse(this.props.location.search);

    this.state = {
      cardAnimaton: "cardHidden",
      busyLoading: false,
      udid: (params.udid ?? localStorage.getItem("udid") ?? process.env.REACT_APP_DEFAULT_UDID ?? ""),
      passphrase: (params.passphrase ?? localStorage.getItem("passphrase") ?? process.env.REACT_APP_DEFAULT_PASSPHRASE ?? ""),

      redirectToDashboard: false,
    };

    this.handleChange =this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const that = this;

    setTimeout(function() {
      that.setState({
        cardAnimaton: "",
      });

      if (that.state.udid != "" && that.state.passphrase != "") {
        that.handleSubmit();
      }
    }, 700);
  }

  async handleChange(event) {
    const {name, value, type, checked} = event.target;
    const target = event.target;
    let cursor = -1;
    
    if (target.setSelectionRange &&
        (
            type === 'text' ||
            type === 'search' ||
            type === 'password' ||
            type === 'url' ||
            type === 'tel'
        )
    ) {
        cursor = ('selectionStart' in event.target ? (event.target.selectionStart ?? -1) : -1);
    }
    await type === "checkbox" ? this.setState({ [name]: checked }) : this.setState({ [name]: value });
    if (cursor != -1 && target.setSelectionRange) {
        target.setSelectionRange(cursor, cursor);
    }
    if (this.state.continuesValidation) {
        this.validateForm(false);
    }
  }

  async handleSubmit(event) {
    const that = this;
    const { classes, intl } = this.props;
    if (event) event.preventDefault();

    that.setState({
      busyLoading: true,
    });

    const token = btoa(this.state.udid+":"+this.state.passphrase);

    await store.dispatch(setToken(token));

    API.get("tablet/me", {
    }).then(res => {
      const data = res.data.data;
      store.dispatch(setauthTablet(data));
      that.setState({
        redirectToDashboard: true,
      });
    }).catch(function (error) {
      console.log(error);

      if ((error.response?.status ?? null) === 401) {
				that.setState({
					busyLoading: false,
					errorMessage: intl.formatMessage({id: "AUTH.LOGIN."+"UDID_OR_PASSPHRASE_INCORRECT"}),
				});
			} else if ((error.response?.status ?? null) === 500) {
				that.setState({
					busyLoading: false,
					errorMessage: intl.formatMessage({id: "AUTH.LOGIN."+"SERVER_ERROR"}),
				});
			} else if ((error.response?.status ?? null) === 503) {
				that.setState({
					busyLoading: false,
					errorMessage: intl.formatMessage({id: "AUTH.LOGIN."+"MAINTENANCE_ERROR"}),
				});
			} else {
				that.setState({
					busyLoading: false,
					errorMessage: intl.formatMessage({id: "AUTH.LOGIN."+"CLIENT_ERROR"}),
				});
			}
    }).finally(function () {
      // always executed
    });
  }

  render() {
    const { classes, intl } = this.props;

    if (this.state.redirectToDashboard === true && this.props.token) {
      return <Redirect push to='/admin/tracker' />
    }

    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <GridContainer>
              <GridItem xs={12}>
                <form onSubmit={this.handleSubmit}>
                  <Card login className={classes[this.state.cardAnimaton]}>
                    <CardHeader
                      className={`${classes.cardHeader} ${classes.textCenter}`}
                      color="rose"
                    >
                      <h4 className={classes.cardTitle}><FormattedMessage id="AUTH.LOGIN.LOG_IN" /></h4>
                      {/* <div className={classes.socialLine}>
                        {[
                          "fab fa-facebook-square",
                          "fab fa-twitter",
                          "fab fa-google-plus"
                        ].map((prop, key) => {
                          return (
                            <Button
                              color="transparent"
                              justIcon
                              key={key}
                              className={classes.customButtonClass}
                            >
                              <i className={prop} />
                            </Button>
                          );
                        })}
                      </div> */}
                    </CardHeader>
                    <CardBody>
                      <CustomInput
                        labelText={intl.formatMessage({id: "AUTH.LOGIN.UDID"})}
                        id="udid"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Email className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          ),
                          name: "udid",
                          value: this.state.udid,
                          onChange: this.handleChange,
                        }}
                      />
                      <CustomInput
                        labelText={intl.formatMessage({id: "AUTH.LOGIN.PASSPHRASE"})}
                        id="passphrase"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Icon className={classes.inputAdornmentIcon}>
                                lock_outline
                              </Icon>
                            </InputAdornment>
                          ),
                          type: "password",
                          autoComplete: "off",
                          name: "passphrase",
                          value: this.state.passphrase,
                          onChange: this.handleChange,
                        }}
                      />
                    </CardBody>
                    <CardFooter className={classes.justifyContentCenter}>
                      {this.state.busyLoading ? 
                        <CircularIndeterminate></CircularIndeterminate>
                        :
                        <Button color="rose" simple size="lg" block type="submit">
                          <FormattedMessage id="AUTH.LOGIN.LETS_GO" />
                        </Button>
                      }
                    </CardFooter>
                  </Card>
                </form>
              </GridItem>
              {this.state.errorMessage ?
                <GridItem xs={12}>
                  <SnackbarContent
                    message={this.state.errorMessage}
                    color="danger"
                  />
                </GridItem>
              : null}
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.auth.token ?? null,
    authTablet: state.auth.authTablet ?? null,
  }
}

export default injectIntl(withStyles(styles, { withTheme: true })(connect(mapStateToProps)(LoginPage)));