import React, {Component} from "react"
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from "react-intl";
import { withStyles } from '@material-ui/core/styles';

// @material-ui/icons
import Update from "@material-ui/icons/Update";

// core components
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import PrivateRoute from './PrivateRoute'
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import CircularIndeterminate from 'components/CircularIndeterminate/CircularIndeterminate.js';

import notificationsStyles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import buttonsStyles from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import GridContainer from "components/Grid/GridContainer";

const hist = createBrowserHistory();

class App extends Component {
    render() {
        const { classes } = this.props;

        return (
            <>
                <div id="updateBar" style={{display: "none"}}>
                    <Snackbar
                        place="tc"
                        color="info"
                        icon={Update}
                        message={<>
                            <GridContainer>
                                <GridItem xs={8} >
                                    <FormattedMessage id="SERVICE_WORKER.UPDATE_AVAILABLE" />
                                </GridItem>
                                <GridItem xs={4} id="updateBarButtonGridItem">
                                    <Button color="primary" round className={classes.marginRight} id="updateBarButton">
                                        <Update className={classes.icons} /> UPDATE
                                    </Button>
                                </GridItem>
                                <GridItem xs={4} id="updateBarSpinnerGridItem" style={{display: "none"}}>
                                    <CircularIndeterminate></CircularIndeterminate>
                                </GridItem>
                            </GridContainer>
                        </>
                        }
                        open={true}
                    />
                </div>
                <Router history={hist}>
                    <Switch>
                        <Route path="/auth/login-page" component={AuthLayout} />
                        <Route path="/auth/logout-page" component={AuthLayout} />
                        <Route path="/auth/storage" component={AuthLayout} />
                        <PrivateRoute path="/admin" component={AdminLayout} authTablet={this.props.authTablet} />
                        <Redirect from="/" to="/admin/tracker" />
                    </Switch>
                </Router>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        authTablet: state.auth.authTablet || null
    }
}

export default injectIntl(withStyles((theme) => ({...buttonsStyles, ...notificationsStyles}), {withTheme: true})(connect(mapStateToProps)(App)));