import * as actionType from './CashActionType';

const initialState = {
    menus: null,
    menu: null,
    icecreamVanReservation: null,
    sale: null,
    spooled_sales: [],
    saleCounter: 0,
    saleOrderCounter: 0,
    paymentCounter: 0,
    latestSaleAt: null,
}

const CashReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_MENUS:
      return {...state, menus: action.payload};
    case actionType.SET_MENU:
        return {...state, menu: action.payload};
    case actionType.SET_ICECREAM_VAN_RESERVATION:
        return {...state, icecreamVanReservation: action.payload};
    case actionType.SET_SALE:
        return {...state, sale: action.payload};
    case actionType.SET_SALE_ID:
        return {
            ...state,
            sale: (state.sale ? state.sale.nonce_id : null) === action.payload.saleNonceId ? {...state.sale, id: action.payload.saleId} : state.sale,
            spooled_sales: state.spooled_sales.map(sale => sale.nonce_id === action.payload.saleNonceId ? {...sale, id: action.payload.saleId} : sale),
        };
    case actionType.SET_SALE_INVOICE_FIELDS:
        return {
            ...state,
            sale: (state.sale ? state.sale.nonce_id : null) === action.payload.saleNonceId ? {
                ...state.sale,
                needs_invoice: action.payload.needs_invoice,
                allow_auto_invoice: action.payload.allow_auto_invoice,
                invoice_discount: action.payload.invoice_discount,
                invoice_name: action.payload.invoice_name,
                invoice_enterprise_nr: action.payload.invoice_enterprise_nr,
                invoice_reference: action.payload.invoice_reference,
                invoice_reference_pending: action.payload.invoice_reference_pending,
                invoice_email: action.payload.invoice_email,
                invoice_street: action.payload.invoice_street,
                invoice_nr: action.payload.invoice_nr,
                invoice_bus: action.payload.invoice_bus,
                invoice_zipcode: action.payload.invoice_zipcode,
                invoice_city: action.payload.invoice_city,
                invoice_country: action.payload.invoice_country,
            } : state.sale,
            spooled_sales: state.spooled_sales.map(sale => sale.nonce_id === action.payload.saleNonceId ? {
                ...sale,
                needs_invoice: action.payload.needs_invoice,
                allow_auto_invoice: action.payload.allow_auto_invoice,
                invoice_discount: action.payload.invoice_discount,
                invoice_name: action.payload.invoice_name,
                invoice_enterprise_nr: action.payload.invoice_enterprise_nr,
                invoice_reference: action.payload.invoice_reference,
                invoice_reference_pending: action.payload.invoice_reference_pending,
                invoice_email: action.payload.invoice_email,
                invoice_street: action.payload.invoice_street,
                invoice_nr: action.payload.invoice_nr,
                invoice_bus: action.payload.invoice_bus,
                invoice_zipcode: action.payload.invoice_zipcode,
                invoice_city: action.payload.invoice_city,
                invoice_country: action.payload.invoice_country,
            } : sale),
        };
    case actionType.SET_SALE_STOP_FIELDS:
        return {
            ...state,
            sale: (state.sale ? state.sale.nonce_id : null) === action.payload.saleNonceId ? {
                ...state.sale,
                datetime_stop: action.payload.datetime,
                latitude_stop: action.payload.latitude,
                longitude_stop: action.payload.longitude,
                icecream_van_reservation_id: action.payload.icecreamVanReservationId,
                needs_invoice: action.payload.needs_invoice,
                allow_auto_invoice: action.payload.allow_auto_invoice,
                invoice_discount: action.payload.invoice_discount,
                invoice_name: action.payload.invoice_name,
                invoice_enterprise_nr: action.payload.invoice_enterprise_nr,
                invoice_reference: action.payload.invoice_reference,
                invoice_reference_pending: action.payload.invoice_reference_pending,
                invoice_email: action.payload.invoice_email,
                invoice_street: action.payload.invoice_street,
                invoice_nr: action.payload.invoice_nr,
                invoice_bus: action.payload.invoice_bus,
                invoice_zipcode: action.payload.invoice_zipcode,
                invoice_city: action.payload.invoice_city,
                invoice_country: action.payload.invoice_country,
                stopFieldSent: false
            } : state.sale,
            spooled_sales: state.spooled_sales.map(sale => sale.nonce_id === action.payload.saleNonceId ? {
                ...sale,
                datetime_stop: action.payload.datetime,
                latitude_stop: action.payload.latitude,
                longitude_stop: action.payload.longitude,
                icecream_van_reservation_id: action.payload.icecreamVanReservationId,
                needs_invoice: action.payload.needs_invoice,
                allow_auto_invoice: action.payload.allow_auto_invoice,
                invoice_discount: action.payload.invoice_discount,
                invoice_name: action.payload.invoice_name,
                invoice_enterprise_nr: action.payload.invoice_enterprise_nr,
                invoice_reference: action.payload.invoice_reference,
                invoice_reference_pending: action.payload.invoice_reference_pending,
                invoice_email: action.payload.invoice_email,
                invoice_street: action.payload.invoice_street,
                invoice_nr: action.payload.invoice_nr,
                invoice_bus: action.payload.invoice_bus,
                invoice_zipcode: action.payload.invoice_zipcode,
                invoice_city: action.payload.invoice_city,
                invoice_country: action.payload.invoice_country,
                stopFieldSent: false
            } : sale),
        };
    case actionType.SET_STOP_FIELDS_SENT:
        return {
            ...state,
            sale: (state.sale ? state.sale.nonce_id : null) === action.payload.saleNonceId ? {...state.sale, stopFieldSent: action.payload.sent} : state.sale,
            spooled_sales: state.spooled_sales.map(sale => sale.nonce_id === action.payload.saleNonceId ? {...sale, stopFieldSent: action.payload.sent} : sale),
        };
    case actionType.ADD_SALE_ORDER:
        return {
            ...state,
            sale: {
                ...state.sale,
                sale_orders: [
                    ...state.sale.sale_orders,
                    action.payload
                ]
            }
        };
    case actionType.SET_SALE_ORDER_ID:
        return {
            ...state,
            sale: (state.sale ? {
                ...state.sale,
                sale_orders: state.sale.sale_orders.map(saleOrder => saleOrder.nonce_id === action.payload.saleOrderNonceId ? {...saleOrder, id: action.payload.saleOrderId} : saleOrder),
            } : null),
            spooled_sales: state.spooled_sales.map(sale => {return {...sale, sale_orders: sale.sale_orders.map(saleOrder => saleOrder.nonce_id === action.payload.saleOrderNonceId ? {...saleOrder, id: action.payload.saleOrderId} : saleOrder)}}),
        };
    case actionType.REMOVE_SALE_ORDER:
        return {
            ...state,
            sale: {
                ...state.sale,
                sale_orders: state.sale.sale_orders.map(saleOrder => saleOrder.nonce_id === action.payload ? {...saleOrder, deleted: true} : saleOrder).filter(saleOrder => saleOrder != null)
            }
        };
    case actionType.PERMANENTLY_REMOVE_SALE_ORDER:
        return {
            ...state,
            sale: (state.sale ? {
                ...state.sale,
                sale_orders: state.sale.sale_orders.map(saleOrder => saleOrder.id === action.payload ? null : saleOrder).filter(saleOrder => saleOrder != null)
            } : null),
            spooled_sales: state.spooled_sales.map(sale => {return {...sale, sale_orders: sale.sale_orders.map(saleOrder => saleOrder.id === action.payload ? null : saleOrder).filter(saleOrder => saleOrder != null)}}),
        };
    case actionType.ADD_PAYMENT:
        return {
            ...state,
            sale: {
                ...state.sale,
                payments: [
                    ...state.sale.payments,
                    action.payload
                ]
            }
        };
    case actionType.SET_PAYMENT_ID:
        return {
            ...state,
            sale: (state.sale ? {
                ...state.sale,
                payments: state.sale.payments.map(payment => payment.nonce_id === action.payload.paymentNonceId ? {...payment, id: action.payload.paymentId} : payment),
            } : null),
            spooled_sales: state.spooled_sales.map(sale => {return {...sale, payments: sale.payments.map(payment => payment.nonce_id === action.payload.paymentNonceId ? {...payment, id: action.payload.paymentId} : payment)}}),
        };
    case actionType.SET_PAYMENT_STATUS:
        return {
            ...state,
            sale: (state.sale ? {
                ...state.sale,
                payments: state.sale.payments.map(payment => payment.id === action.payload.paymentId ? {...payment, status: action.payload.paymentStatus} : payment),
            } : null),
            spooled_sales: state.spooled_sales.map(sale => {return {...sale, payments: sale.payments.map(payment => payment.id === action.payload.paymentId ? {...payment, status: action.payload.paymentStatus} : payment)}}),
        };
    case actionType.MOVE_SALE_TO_SPOOL:
        return {
            ...state,
            sale: null,
            spooled_sales: [
                ...state.spooled_sales,
                state.sale,
            ].filter(sale => sale != null)
        };
    case actionType.PERMANENTLY_REMOVE_SALE_FROM_SPOOL:
        return {
            ...state,
            spooled_sales: state.spooled_sales.map(sale => (sale != null && sale.id === action.payload) ? null : sale).filter(sale => sale != null),
        };
    case actionType.INCREMENT_SALE_COUNTER:
        return {
            ...state,
            saleCounter: (state.saleCounter || 0)+1,
        };
    case actionType.INCREMENT_SALE_ORDER_COUNTER:
        return {
            ...state,
            saleOrderCounter: (state.saleOrderCounter || 0)+1,
    };
    case actionType.INCREMENT_PAYMENT_COUNTER:
        return {
            ...state,
            paymentCounter: (state.paymentCounter || 0)+1,
        };
    case actionType.SET_LATEST_SALE_AT:
        return {
            ...state,
            latestSaleAt: action.payload,
        };
    default:
      return state
  }
}

export default CashReducer;