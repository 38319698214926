/*!

=========================================================
* Material Dashboard PRO React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createStore } from 'redux';
import {Provider} from 'react-redux'
import {combineReducers} from 'redux'
import I18nProvider from "i18n/I18nProvider";
import * as serviceWorker from 'serviceWorker';

// core components
import App from "App.js";

import API from './api';
import { setupAxios } from "utils";

import AuthReducer from'./Redux/AuthReducer';
import I18nReducer from'./Redux/I18nReducer';
import CashReducer from "Redux/CashReducer";
import LayersReducer from "Redux/LayersReducer";
import DailyStorageReducer from "Redux/DailyStorageReducer";

import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";

function saveToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch(e) {
    console.log(e);
  }
}

function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch(e) {
    console.log(e);
    return undefined;
  }
}

const persistedState = loadFromLocalStorage();

export let store = createStore(combineReducers({auth: AuthReducer, I18n: I18nReducer, cash: CashReducer, layers: LayersReducer, dailyStorage: DailyStorageReducer}), persistedState, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

store.subscribe(() => saveToLocalStorage(store.getState()));

setupAxios(API, store);

ReactDOM.render(
  <Provider store={store}>
    <div>
      <I18nProvider>
        <App></App>
      </I18nProvider>
    </div>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();