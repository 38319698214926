import cx from "classnames";
import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
// creates a beautiful scrollbar
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { hasPermission } from "utils.js";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import CircularIndeterminate from 'components/CircularIndeterminate/CircularIndeterminate.js';
import Footer from "components/Footer/Footer.js";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import SaleSpooler from "./shared/SaleSpooler.js";

import fixedRoutes from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";

import { lazyRetry } from "lazyRetry";

const CustomPage = lazyRetry(() => import("views/Custom/CustomPage"));

// var ps;

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const { authTablet, ...rest } = props;
  const applicableCustomFrontendables = (authTablet?.company?.custom_frontendables ?? []).filter(route => route.platform == "ICETRUCK");
	const routes = fixedRoutes.concat(applicableCustomFrontendables);
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(true);
  // eslint-disable-next-line no-unused-vars
  const [image, setImage] = React.useState(require("assets/img/sidebar-2.jpg"));
  // eslint-disable-next-line no-unused-vars
  const [color, setColor] = React.useState("blue");
  // eslint-disable-next-line no-unused-vars
  const [bgColor, setBgColor] = React.useState("black");
  // const [hasImage, setHasImage] = React.useState(true);
  // eslint-disable-next-line no-unused-vars
  const [fixedClasses, setFixedClasses] = React.useState("dropdown");
  // eslint-disable-next-line no-unused-vars
  const [logo, setLogo] = React.useState(require("assets/img/logo-white.svg"));
  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      // [classes.mainPanelWithPerfectScrollbar]:
        // navigator.platform.indexOf("Win") > -1
    });
  // ref for main panel div
  const mainPanel = React.createRef();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      // ps = new PerfectScrollbar(mainPanel.current, {
      //   suppressScrollX: true,
      //   suppressScrollY: false
      // });
      // document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        // ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  });
  // functions for changeing the states from components
  // const handleImageClick = image => {
  //   setImage(image);
  // };
  // const handleColorClick = color => {
  //   setColor(color);
  // };
  // const handleBgColorClick = bgColor => {
  //   switch (bgColor) {
  //     case "white":
  //       setLogo(require("assets/img/logo.svg"));
  //       break;
  //     default:
  //       setLogo(require("assets/img/logo-white.svg"));
  //       break;
  //   }
  //   setBgColor(bgColor);
  // };
  // const handleFixedClick = () => {
  //   if (fixedClasses === "dropdown") {
  //     setFixedClasses("dropdown show");
  //   } else {
  //     setFixedClasses("dropdown");
  //   }
  // };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const isFullscreenRoute = () => {
    return (
      window.location.pathname == "/admin/tracker" ||
      window.location.pathname == "/admin/cashier" ||
      window.location.pathname.indexOf("/admin/custom") >= 0
    );
  };
  const getActiveRoute = routes => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse || routes[i].mdl == "App\\CustomFrontendPageGroup") {
        let collapseActiveRoute = getActiveRoute(routes[i].views ?? routes[i].custom_frontendables);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        const index = window.location.href.indexOf((routes[i].layout ?? "/admin") + (routes[i].matchPath ?? routes[i].path ?? "/custom/"+encodeURIComponent(routes[i].name)));
				const nextChar = index > -1 ? window.location.href.charAt(index + ((routes[i].layout ?? "/admin") + (routes[i].matchPath ?? routes[i].path ?? "/custom/"+encodeURIComponent(routes[i].name))).length) : null;
				if (
					index > -1 && (nextChar == "" || nextChar == "/" || nextChar == "\\" || nextChar == "?" || nextChar == "#")
				) {
					return routes[i].name;
				}
      }
    }
    return activeRoute;
  };
  const getRoutes = routes => {
    return routes.filter(function(route) {
      return (typeof route.permissions == 'undefined' || route.permissions == null || route.permissions == "" || route.permissions.length == 0 || hasPermission(authTablet, route.permissions));
    }).map((prop, key) => {
      if (prop.collapse || prop.mdl == "App\\CustomFrontendPageGroup") {
        return getRoutes(prop.views ?? prop.custom_frontendables);
      }
      if (prop.mdl == "App\\CustomFrontendPage") {
				return (
					<Route
						path={(prop.layout ?? "/admin") + (prop.path ?? "/custom/"+prop.name)}
						render={(props) => <CustomPage {...props} url={prop.url} allow={prop.allow ?? ""} />}
						key={key}
					/>
				);
      } else if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logoText={"ICEtruck"}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}
        {...rest}
      />
      <div className={mainPanelClasses} ref={mainPanel} id="scrollanchor">
        <Suspense fallback={<CircularIndeterminate />}>
          <AdminNavbar
            sidebarMinimize={sidebarMinimize.bind(this)}
            miniActive={miniActive}
            brandText={getActiveRoute(routes)}
            handleDrawerToggle={handleDrawerToggle}
            {...rest}
          />
          {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {!isFullscreenRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>
                <Switch>
                  {getRoutes(routes)}
                  <Redirect from="/admin" to="/admin/tracker" />
                </Switch>
              </div>
            </div>
          ) : (
            <div className={classes.map+" "+classes.mainPanelWithPerfectScrollbar}>
              <Switch>
                {getRoutes(routes)}
                <Redirect from="/admin" to="/admin/tracker" />
              </Switch>
            </div>
          )}
          <SaleSpooler />
          {!isFullscreenRoute() ? <Footer fluid /> : null}
          {/* <FixedPlugin
            handleImageClick={handleImageClick}
            handleColorClick={handleColorClick}
            handleBgColorClick={handleBgColorClick}
            color={color}
            bgColor={bgColor}
            bgImage={image}
            handleFixedClick={handleFixedClick}
            fixedClasses={fixedClasses}
            sidebarMinimize={sidebarMinimize.bind(this)}
            miniActive={miniActive}
          /> */}
        </Suspense>
      </div>
    </div>
  );
}
