import { clearAuth } from "Redux/AuthActions";
import { store } from "index";
import React from "react";
import { Redirect } from "react-router-dom";

class LogoutPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          redirectToLogin: false,
        };
    }
    
    async componentDidMount() {
        await store.dispatch(clearAuth());
        this.setState({
            redirectToLogin: true,
        });
    }

    render() {
        if (this.state.redirectToLogin === true) {
            return <Redirect to='/auth/login-page' />
        }

        return <></>;
    }
}

export default LogoutPage;